import React, { Component } from 'react';
import '../App.css';
import footPrint2 from '../assets/rm.png';
import $ from "jquery";
import fireball from '../assets/dragon.png';

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});

class Roadmap extends Component {


    render() {
        return (

            <div class="rmbg">

                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>

                <div class="storyH">ROADMAP</div>

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>ERA - I</h2>
                            <h4>Embark on an Epic Quest - Five Kingdoms Clash in an All-Out Battle to Defend Their Lands and Secure Their Legacy in the Annals of History!</h4>
                            <p><img class="fire" src={fireball} /><span class="doing">Unveiling the Launch of the Official Website!</span></p>
                            <p><span class="next"><img class="fireGlow" src={fireball} />A Glimpse of Unimaginable Riches - 25 Giveaways Up for Grabs!</span></p>
                            <p><span class="next"><img class="fireGlow" src={fireball} />Unleashing a Storm of Excitement - 50 Whitelisted Users will be Selected for Free Mint!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Uncovering Hidden Treasures - Launching the NFT Collections!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Blasting Off into the Stratosphere - Launching the Token!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">A Journey to the Top - Listing the Token in Exchanges!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Enter the Arena of Titans - A Video Competition with Enormous Prizes Awaits!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Enter the Realm of Endless Possibilities - Launching the Game as a Play-to-Earn Experience, Where Users Can Unleash Their NFTs and Reap the Rewards of Wealth!</span></p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>ERA - II</h2>
                            <h4> Brace yourself for an epic tale of unyielding, relentless, and unstoppable attacks from a species hailing from the future!</h4>
                            <p><img class="fire" src={fireball} /><span class="grayFonts"> A limited few, hand-selected, 100 whitelisted users, will be chosen to receive a once-in-a-lifetime opportunity: FREE MINT!</span> </p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts"> Brace yourselves, for the epic unveiling of the "Warriors from the Future" NFT Collection!</span> </p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts"> The stage is set, the competition is fierce, and the prizes are beyond imagination! Get ready to showcase your skills in the most electrifying video competition of the year!</span> </p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts"> Are you ready to take on the challenge? To rise to the top? To claim your throne? Get ready for the launch of the all-new version of the play-to-earn game!</span> </p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>ERA - III</h2>
                            <h4>The Rise of a New Era: A Sixth Kingdom Emerges in the Heart of Lemuria, Led by a Majestic King, Admired by All and Destined to Vanquish the Fierce Threats of the Future!</h4>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Only 100 lucky users will be handpicked for the exclusive whitelist!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Fifty lavish giveaways await those who participate!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">A video competition of epic proportions with massive prizes to be won!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">Get ready to behold the grand unveiling of the breathtaking "Manu Kingdom" NFT Collection!</span></p>
                            <p><img class="fire" src={fireball} /><span class="grayFonts">And brace yourself for the highly anticipated launch of the next-generation "Play to Earn" game!</span></p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                    </ul>
                </div>

            </div>


        );

    }
}

export default Roadmap;