import React, { Component } from 'react';
 
class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

        <div class="storyH">FAQ</div>

          <details >
            <summary>What are the opportunities for users to generate revenue from the project?</summary>
            <div class="faq__content">
              <p>I. NFTs can be traded based on their perceived value by their owners.</p>
              <p>II. NFTs can be utilized within in-game battles, loot, and wars, providing opportunities for monetary gain.</p>
            </div>
          </details>
          <details>
            <summary>What will be the Blockchain Network?</summary>
            <div class="faq__content">
            <p>Ethereum</p>
            </div>
          </details>
          <details>
            <summary>What will be the minting price of an NFT?</summary>
            <div class="faq__content">
            <p>0.009 ETH</p>
            </div>
          </details>

          <details>
            <summary>How to get the Free Mints?</summary>
            <div class="faq__content">
              <p>You have to participate in our questioneres and win them</p>
            </div>
          </details>

          <details>
            <summary>Where can I find the questioneres and the answers for them?</summary>
            <div class="faq__content">
            <p>All the questioneres will be based on our story and other details within the website and questioneres will be published on our official <a href="https://twitter.com/LordsOfLemuria" target="_blank">twitter</a> account</p>
            </div>
          </details>         

        </div>
      </div>


    )
  }
}

export default FAQ;

