import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import map from '../assets/MAPALL.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">Story</div>

                    <div class="about">

                        <img class="map" src={map} />

                        <div class="aboutP">
                            <p>In a world long forgotten, there once stood the magnificent land of Lemuria. A place of great wonder and untold power, where beings of unparalleled might roamed the earth. The energy of this ancient realm still echoed through the ages, and its essence can be felt in the very earth that now lies beneath the seas.</p>
                            <p>But despite its long-lost status, the spirit of Lemuria lives on in the hearts and minds of those who know its story. And now, a new chapter begins, as five tribes come together to reclaim the power of their forebears. Each tribe, a mighty kingdom in its own right, boasts beings of incredible strength and skill, ready to do battle and claim their rightful place in the world.</p>
                            <p>As the stage is set for this epic struggle, the forces of light and dark gather, each vying for control over the land of Lemuria. Will the tribes band together and restore the power of their lost heritage, or will they fall to the cunning and might of their enemies? The fate of Lemuria rests in the hands of the five kingdoms, and the power of the beings that call it home.</p>
                            <p>In this mythical world of adventure and danger, only the bravest and most cunning will emerge victorious. Will you be one of the chosen few, ready to claim your place among the legends of Lemuria? Join us, and discover the power of the ancient realm for yourself.</p>
                            <p>From the depths of time and the sands of history, emerges a land steeped in mystery and wonder. A place where legends still linger, and where the echoes of ancient power can still be felt. A land known to the world as Sri Lanka, but to those in the know, it is the fabled home of Lemuria, where once, mighty and powerful beings roamed the earth.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Story;

